import {
  HOME_PAGE_QUERY_FAILED,
  HOME_PAGE_QUERY_REQUEST,
  HOME_PAGE_QUERY_SUCCESS,
} from "../actionTypes"

const initState = {
  homepageLoader: false,
  page: {},
  posts: {},
}

export default function homepageReducer(state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case HOME_PAGE_QUERY_REQUEST:
      return {
        ...state,
        homepageLoader: true,
      }
    case HOME_PAGE_QUERY_SUCCESS:
      return {
        page: payload?.page,
        posts: payload?.posts,
        homepageLoader: false,
      }
    case HOME_PAGE_QUERY_FAILED:
      return {
        ...state,
        homepageLoader: false,
      }
    default:
      return state
  }
}
