import { combineReducers } from "redux"
import homepageReducer from "./homepage"
import contactpagereducer from "./contactpage"

const rootReducer = combineReducers({
  homepage: homepageReducer,
  contactpage: contactpagereducer
})

export default rootReducer
