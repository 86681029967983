//Homepage Action Types
export const HOME_PAGE_QUERY_REQUEST = "HOME_PAGE_QUERY_REQUEST"
export const HOME_PAGE_QUERY_SUCCESS = "HOME_PAGE_QUERY_SUCCESS"
export const HOME_PAGE_QUERY_FAILED = "HOME_PAGE_QUERY_FAILED"

//ContactPage Action Types
export const CONTACT_PAGE_QUERY_REQUEST = "CONTACT_PAGE_QUERY_REQUEST"
export const CONTACT_PAGE_QUERY_SUCCESS = "CONTACT_PAGE_QUERY_SUCCESS"
export const CONTACT_PAGE_QUERY_FAILED = "CONTACT_PAGE_QUERY_FAILED"

//Blogpage Action Types
export const BLOG_PAGE_QUERY_REQUEST = "BLOG_PAGE_QUERY_REQUEST"
export const BLOG_PAGE_QUERY_SUCCESS = "BLOG_PAGE_QUERY_SUCCESS"
export const BLOG_PAGE_QUERY_FAILED = "BLOG_PAGE_QUERY_FAILED"