import {
    CONTACT_PAGE_QUERY_FAILED,
    CONTACT_PAGE_QUERY_SUCCESS,
    CONTACT_PAGE_QUERY_REQUEST,
  } from "../actionTypes"
  
  const initState = {
    contactPageLoder: false,
    page: {}
  }
  
  export default function contactpagereducer(state = initState, action) {
    const { type, payload } = action
    switch (type) {
      case CONTACT_PAGE_QUERY_REQUEST:
        return {
          ...state,
          contactPageLoder: true,
        }
      case CONTACT_PAGE_QUERY_SUCCESS:
        return {
          page: payload?.page,
          contactPageLoder: false,
        }
      case CONTACT_PAGE_QUERY_FAILED:
        return {
          ...state,
          contactPageLoder: false,
        }
      default:
        return state
    }
  }